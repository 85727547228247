// Here you can add other styles

// Sidebar Hover Style
.c-sidebar .c-sidebar-nav-link.c-active {
  background-color: #26334d;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #26334d;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-dropdown-toggle {
  background: #26334d;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav>.c-sidebar-nav-dropdown:hover {
  background: #26334d;
}

.widget-custom {
  .text-value {
    font-size: 18px;
  }

  .text-muted {
    font-size: 13px;
    letter-spacing: 2px;
  }
}

.vh-70 {
  min-height: 70vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.card {
  border-radius: 5px;
  border: 0;
  box-shadow: 0px 2px 15px #d6d3d3b8;

  .card-header {
    font-weight: 800;
    border-radius: 5px 5px 10px 10px;
    box-shadow: 0 2px 20px #d6d3d3b8;
    border: 0;
    padding: 1.25rem;
  }

  .card-footer {
    font-weight: 800;
    border-radius: 10px 10px 5px 5px;
    box-shadow: 0 -2px 20px #d6d3d3b8;
    border: 0;
    padding: 1.25rem;
  }

  .card-body.has-table {
    div.row:not(.table-responsive) {
      padding: 1rem 1.25rem 1.5rem 1rem;
      border-bottom: 1px solid #efefef;
    }
  }

  .table {
    th {
      border-top: 0;
    }

    th,
    td {
      border-top-color: #efefef;
      border-bottom-color: #efefef;

      &:first-child {
        padding-left: 1.3rem;
      }

      &:last-child {
        padding-right: 1.3rem;

        svg {
          right: 13px;
        }
      }
    }
  }
}

.table {
  tr {
    th {
      padding: 0.5rem 0.75rem;
      vertical-align: middle;

      &:first-child {
        .form-check-input {
          width: 15px;
          height: 15px;
          margin-top: -7px;
          opacity: 0.4;

          &:checked {
            opacity: 1;
          }
        }
      }
    }

    td {
      vertical-align: middle;

      &:first-child {
        .form-check-input {
          width: 15px;
          height: 15px;
          margin-top: -8px;
          opacity: 0.4;

          &:checked {
            opacity: 1;
          }
        }
      }
    }

    &:hover {
      td {
        &:first-child {
          .form-check-input {
            opacity: 1;
          }
        }
      }

      th {
        &:first-child {
          .form-check-input {
            opacity: 1;
          }
        }
      }
    }
  }
}

.react-confirm-alert-body {
  box-shadow: 0 20px 75px #ccc !important;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.react-confirm-alert-button-group {
  display: flex;
  flex-direction: row-reverse;

  .btn-danger {
    background: #ff1d10 !important;
    border-color: #ff1d10 im !important;
  }
}

.card {
  .card-header {
    &.with-tab {
      padding: 0;
    }

    .nav {
      border: none;

      .nav-item {
        border: none;

        .nav-link {
          font-size: 14px;
          padding: 15px 0px 15px 30px;
          color: #ccc;
          border: 0;

          &:hover {
            border: 0;
          }

          &.active {
            border: none;
            background-color: inherit;
            color: #000;
          }
        }
      }
    }
  }
}

.modal {
  .modal-header {
    &.has-tab {
      padding: 0;
      background-color: #ebedef;
      border: 0;


      .close {
        margin: -4px 10px;
      }

      .nav {
        border: 0;
        background-color: #ebedef;

        .nav-item {
          border: 0;
        }

        .nav-link {
          padding: 13px 35px;
          background-color: #ebedef;
          color: #333;

          &.active {
            color: $primary;
            background-color: #fff;
          }
        }
      }
    }
  }

  .fixed-modal {
    max-height: 65vh;
    min-height: 65vh;
    overflow-y: scroll;
  }
}
